import type { ReactNode } from 'react';
import { isValidElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { $path } from 'safe-routes';

import { Button } from '~/components/ui/button';

import { ErrorPageTemplate } from './error-page-template';

const ns = 'page' satisfies Ns;
/**
 * Check if the value is a valid ReactNode
 * @param value
 */
const isValid = (value: unknown): value is ReactNode => {
  if (typeof value === 'string') return true;
  if (typeof value === 'number') return true;
  return typeof value === 'object' && isValidElement(value);
};

export const BadRequest = ({ error }: { error: unknown }) => {
  const content = isValid(error) ? error : null;
  const { t } = useTranslation(ns);

  return (
    <ErrorPageTemplate
      title={
        <Trans ns={ns} i18nKey='400.title' t={t}>
          400
        </Trans>
      }
      description={
        <Trans ns={ns} i18nKey='400.description' t={t}>
          Bad request
        </Trans>
      }
      content={content}
      footer={
        <>
          <Button variant='outline' asChild>
            <Link to={$path('/')}>
              <Trans ns={ns} i18nKey='button.home' t={t}>
                Return to Home
              </Trans>
            </Link>
          </Button>
          <Button variant='outline' asChild>
            <Link to='.'>
              <Trans ns={ns} i18nKey='button.refresh' t={t}>
                Refresh
              </Trans>
            </Link>
          </Button>
        </>
      }
    />
  );
};
