import type { ComponentProps, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Toaster as Sonner, toast } from 'sonner';

import type { ToastMessage } from '~/.server';
import { ApplicationIcon } from '~/components/application-icons';
import { useTheme } from '~/features/providers/theme-provider';

type ToasterProps = ComponentProps<typeof Sonner> & Readonly<{ toast: ToastMessage | undefined }>;

const Toaster = ({ toast: routeToast, ...props }: ToasterProps) => {
  const [theme] = useTheme();
  const [, setToastId] = useState<string | number | undefined>();

  useEffect(() => {
    setToastId(toastId => {
      if (!toastId && routeToast) {
        const { iconType, title, text, variant } = routeToast;

        if (variant === 'destructive') {
          return toast.error(title as string, {
            description: text as ReactNode,
            icon: iconType ? <ApplicationIcon icon={iconType} /> : null,
          });
        }
        return toast(title as string, {
          description: text as ReactNode,
          icon: iconType ? <ApplicationIcon icon={iconType} /> : null,
        });
      }
      return undefined;
    });
  }, [routeToast]);

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className='toaster group'
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          title: 'group-[.toast]:text-primary-foreground',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
