import type { ReactNode } from 'react';

export const ErrorPageTemplate = ({
  title,
  description,
  content,
  footer,
}: {
  title: ReactNode;
  description: ReactNode;
  content: ReactNode;
  footer: ReactNode;
}) => {
  return (
    <div className='flex min-h-screen flex-col items-center justify-center bg-background text-foreground'>
      <h1 className='mb-4 text-6xl font-bold'>{title}</h1>
      <h2 className='mb-4 text-2xl font-semibold'>{description}</h2>
      <p className='mb-8'>{content}</p>
      <div className='flex gap-2'>{footer}</div>
    </div>
  );
};
