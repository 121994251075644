import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { $path } from 'safe-routes';

import { Button } from '~/components/ui/button';

import { ErrorPageTemplate } from './error-page-template';

const ns = 'page' satisfies Ns;
export const NotFound = () => {
  const { t } = useTranslation(ns);
  return (
    <ErrorPageTemplate
      title={
        <Trans ns={ns} i18nKey='404.title' t={t}>
          404
        </Trans>
      }
      description={
        <Trans ns={ns} i18nKey='404.description' t={t}>
          Page Not Found
        </Trans>
      }
      content={
        <Trans ns={ns} i18nKey='404.content' t={t}>
          Oops! The page you're looking for doesn't exist. It might have been moved or deleted.
        </Trans>
      }
      footer={
        <Link to={$path('/')} className='underline'>
          <Button variant='outline'>
            <Trans ns={ns} i18nKey='button.home' t={t}>
              Return to Home
            </Trans>
          </Button>
        </Link>
      }
    />
  );
};
