import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { $path } from 'safe-routes';

import { Button } from '~/components/ui/button';

import { ErrorPageTemplate } from './error-page-template';

const ns = 'page' satisfies Ns;
export const Unauthorized = () => {
  const { t } = useTranslation(ns);
  return (
    <ErrorPageTemplate
      title={
        <Trans ns={ns} i18nKey='401.title' t={t}>
          401
        </Trans>
      }
      description={
        <Trans ns={ns} i18nKey='401.description' t={t}>
          Unauthorized
        </Trans>
      }
      content={
        <Trans ns={ns} i18nKey='401.content' t={t}>
          Oops! It looks like you don’t have permission to access this page.
        </Trans>
      }
      footer={
        <Button variant='outline' asChild>
          <Link to={$path('/')} className='block w-full rounded px-4 py-2'>
            <Trans ns={ns} i18nKey='button.home' t={t}>
              Return to Home
            </Trans>
          </Link>
        </Button>
      }
    />
  );
};
