import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Links, Meta, Scripts, ScrollRestoration } from 'react-router';
import { useChangeLanguage } from 'remix-i18next/react';
import { useHydrated } from 'remix-utils/use-hydrated';

import type { SupportedLanguages } from '~/features/i18n';
import { NonFlashOfWrongThemeEls, useTheme } from '~/features/providers/theme-provider';
import { cn } from '~/libs/utils';

export const Document = ({
  locale,
  children,
}: Readonly<
  PropsWithChildren<{
    locale: SupportedLanguages;
  }>
>) => {
  // Get the locale from the loader
  const { i18n } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  const [theme, htmlTheme] = useTheme();
  const hydrated = useHydrated();
  return (
    <html lang={locale} className={cn(theme, htmlTheme)} dir={i18n.dir()}>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        {hydrated ? <meta name='color-scheme' content={htmlTheme === 'light' ? 'light dark' : 'dark light'} /> : null}
        <Meta />
        <Links />
        <NonFlashOfWrongThemeEls />
      </head>
      <body className='bg-muted text-foreground transition-all duration-75 motion-reduce:!transition-none'>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
};
